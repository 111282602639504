import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { patchAddSiteStep4QueryFn } from "../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import { usePublicContext } from "../../../../providers/ReactPublicContextProvider";
import { useSearchParams } from "react-router-dom";
import { addSiteStep4Schema } from "../../../../utils/forms-schemas";
import Hr from "../../ui/Hr";
import ErrorFormik from "../../ui/ErrorFormik";
import Label from "../../ui/custom-inputs/Label";
import CustomInput from "../../ui/custom-inputs/CustomInput";
import { AGE_SUITABILITY_OPTIONS } from "../../../../utils/constants";
import DynamicPricingFields from "../../ui/DynamicPricingFields";

const StepFour = ({ handleNext, handlePrev, ExperienceId, siteInfo }) => {
  const { setIsLoading } = usePublicContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      pricingInformation: [
        {
          personType: "",
          price: 0,
          minUnit: 0,
          maxUnit: 0,
          discount: 0,
        },
      ],
      capacity: 0,
      cancellationTime: 74,
    },
    enableReinitialize: true,
    validationSchema: addSiteStep4Schema,
    onSubmit: onSubmit,
  });

  const {
    mutate,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: patchAddSiteStep4QueryFn,
    onSuccess(data) {
      setSearchParams({ id: data?._id, step: 5 });
      handleNext();
    },
    onError(error) {
      console.error("error Site Step 4: ", error);
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    setValues({
      pricingInformation:
        siteInfo?.site?.pricingInformation.length > 0
          ? siteInfo?.site?.pricingInformation
          : [],
      capacity: siteInfo?.site?.capacity || 0,
      cancellationTime: siteInfo?.site?.cancellationTime || 74,
    });
  }, [siteInfo]);

  useEffect(() => {
    setIsLoading(isLoadingMutate);
  }, [isLoadingMutate]);


  function onSubmit() {
    const conditions = [
      {
        check: (values) =>
          values?.pricingInformation.some(
            (item) => item?.personType === AGE_SUITABILITY_OPTIONS[0]?.value,
          ),
        message: "At least one Adult person must be included!",
      },
      {
        check: (values) =>
          values?.pricingInformation.every(
            (item) => Number(item?.maxUnit) <= Number(values?.capacity),
          ),
        message:
          "The Max Unit for a person type must not exceed the specified capacity",
      },
      // >>> Commented the validation to check the coverage of capacity in pricing <<<
      // {
      //   check: (values) =>
      //     AGE_SUITABILITY_OPTIONS?.every((suitabilityOption) => {
      //       // Check if there person type exist in the selected values 
      //       if (values?.pricingInformation.find((item) => item.personType === suitabilityOption.value)) {
      //         // If the person type is selected check if the max unit covered the capacity.
      //         return values?.pricingInformation?.some(
      //           (currentValue) =>
      //             currentValue?.personType === suitabilityOption?.value &&
      //             Number(currentValue?.maxUnit) === Number(values?.capacity),
      //         );
      //       }
      //       // Return true if the person type is not in the list
      //       return true;
      //     }),
      //   message: `Prices for selected person types must be covered for all selected capacity from 1 to ${values?.capacity}`,
      // },
      // {
      //   check: (values) =>
      //     AGE_SUITABILITY_OPTIONS?.every((suitabilityOption) => {
      //       // // Check if there person type exist in the selected values 
      //       if (values?.pricingInformation.find((item) => item.personType === suitabilityOption.value)) {

      //          // If the person type is selected check if the max unit covered the capacity.
      //         return values?.pricingInformation?.some(
      //           (currentValue) =>
      //             currentValue?.personType === suitabilityOption?.value &&
      //             Number(currentValue?.minUnit) === 1,
      //         );
      //       }
      //       // Return true if the person type is not in the list
      //       return true;
      //     }),
      //   message: `Prices for selected person types must be covered for all selected capacity from 1 to ${values?.capacity}`,
      // },
    ];

    for (let condition of conditions) {
      if (!condition.check(values)) {
        toast.error(condition.message);
        return;
      }
    }

    const val = values.pricingInformation.map((item) => {
      const newObj = {
        ...item,
        price: Number(item.price),
        minUnit: Number(item.minUnit),
        maxUnit: Number(item.maxUnit),
        discount: Number(item.discount),
      };

      if (newObj.discount === 0) {
        delete newObj.discount;
      }

      return newObj;
    });

    mutate({
      id: ExperienceId,

      pricingInformation: val,
      capacity: values?.capacity,
      cancellationTime: values.cancellationTime,
    });
  }

  const handlePricingChange = (values) => {
    setFieldValue("pricingInformation", values);
  };

  const handleErrors = async () => {
    const err = Object.values(errors);
    if (err.length > 0) {
      toast.error(err[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <h4 className=''>General Information</h4>
        <Hr />
        <div className='row'>
          {/* Capacity */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label forId='capacity' text='Capacity' isRequired={true} />
              <CustomInput
                withFocus={true}
                placeholder='Capacity'
                id='capacity'
                name='capacity'
                type='number'
                value={values?.capacity}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.capacity}
                isTouched={touched?.capacity}
                error={errors?.capacity}
              />
            </div>
          </div>
          {/* Cancellation Time */}
          <div className='col-lg-6'>
            <div className='form-group mb-3'>
              <Label
                forId='cancellationTime'
                text='Cancellation Time'
                isRequired={false}
              />
              <CustomInput
                withFocus={true}
                placeholder='Transportation Title'
                id='cancellationTime'
                name='cancellationTime'
                type='number'
                value={values?.cancellationTime}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorFormik
                isError={errors?.cancellationTime}
                isTouched={touched?.cancellationTime}
                error={errors?.cancellationTime}
              />
            </div>
          </div>
        </div>
      </section>
      {/* Pricing Information */}
      <section>
        <h4 className=''>Pricing Information</h4>
        <Hr />
        <div>
          <DynamicPricingFields
            inputFields={[
              {
                id: "personType",
                name: "personType",
                label: "Person Type",
                placeholder: "Enter Person Type",
                type: "select",
                options: AGE_SUITABILITY_OPTIONS,
                isRequired: true,
              },
              {
                id: "price",
                name: "price",
                label: "Price (JOD)",
                placeholder: "Enter Price",
                type: "number",
                isRequired: true,
              },
              {
                id: "minUnit",
                name: "minUnit",
                label: "Min Unit",
                placeholder: "Enter Min Unit",
                type: "number",
                isRequired: true,
              },
              {
                id: "maxUnit",
                name: "maxUnit",
                label: "Max Unit",
                placeholder: "Enter Max Unit",
                type: "number",
                isRequired: true,
              },
              {
                id: "discount",
                name: "discount",
                label: "Discount",
                placeholder: "Enter Discount",
                type: "number",
                isRequired: false,
              },
            ]}
            textButton='Add Pricing Slot'
            fieldName={"pricingInformation"}
            onChange={handlePricingChange}
            val={values?.pricingInformation}
            NewEntryShap={{
              personType: "",
              price: 0,
              minUnit: 0,
              maxUnit: 0,
              discount: 0,
            }}
            errors={errors}
            touched={touched}
            tableLabel={[
              { label: "Person Type", name: "personType" },
              { label: "Min Unit", name: "minUnit" },
              { label: "Max Unit", name: "maxUnit" },
              { label: "Price (JOD)", name: "price" },
              { label: "Discount", name: "discount" },
              { label: "Actions", name: "actions" },
            ]}
          />
        </div>
      </section>
      <ErrorFormik
        isError={errors?.pricingInformation}
        isTouched={touched?.pricingInformation}
        error={errors?.pricingInformation}
      />
      <Hr />
      {/* Buttons */}
      <div className='text-end toolbar toolbar-bottom ps-2'>
        <button
          type='button'
          className='btn btn-secondary sw-btn-prev me-1'
          onClick={() => handlePrev()}
        >
          Prev
        </button>
        <button
          type='submit'
          onClick={handleErrors}
          className='btn btn-primary sw-btn-next ms-1'
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default StepFour;
