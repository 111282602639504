// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-snow {
    .ql-picker {
        &.ql-size {

            .ql-picker-label,
            .ql-picker-item {
                &::before {
                    content: attr(data-value) !important;
                }
            }
        }
    }
}

.ql-snow {
    .ql-picker {
        &.ql-size {

            .ql-picker-label:not([data-value]),
            .ql-picker-item:not([data-value]) {
                &::before {
                    content: 'Normal' !important;
                }
            }
        }
    }
}

.ql-toolbar .ql-formats .ql-break {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #444444;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: 600;
}

.ql-toolbar .ql-formats .ql-break::before {
    content: "BR";
    margin-right: 5px;
}

.ql-toolbar .ql-formats .ql-break:hover {
    color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/jsx/components/ui/custom-inputs/CustomEditor/style.css"],"names":[],"mappings":"AAAA;IACI;QACI;;YAEI;;gBAEI;oBACI,oCAAoC;gBACxC;YACJ;QACJ;IACJ;AACJ;;AAEA;IACI;QACI;;YAEI;;gBAEI;oBACI,4BAA4B;gBAChC;YACJ;QACJ;IACJ;AACJ;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".ql-snow {\n    .ql-picker {\n        &.ql-size {\n\n            .ql-picker-label,\n            .ql-picker-item {\n                &::before {\n                    content: attr(data-value) !important;\n                }\n            }\n        }\n    }\n}\n\n.ql-snow {\n    .ql-picker {\n        &.ql-size {\n\n            .ql-picker-label:not([data-value]),\n            .ql-picker-item:not([data-value]) {\n                &::before {\n                    content: 'Normal' !important;\n                }\n            }\n        }\n    }\n}\n\n.ql-toolbar .ql-formats .ql-break {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    color: #444444;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    font-weight: 600;\n}\n\n.ql-toolbar .ql-formats .ql-break::before {\n    content: \"BR\";\n    margin-right: 5px;\n}\n\n.ql-toolbar .ql-formats .ql-break:hover {\n    color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
