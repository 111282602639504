import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAmenitiesList,
  getAmenityCategoriesList,
} from "../../../../../queries/queryFunctions/publicQueryFns/getQueryFns";
import { usePublicContext } from "../../../../../providers/ReactPublicContextProvider";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import SlideOver from "../../../../layouts/SlideOver";
import ErrorFormik from "../../../ui/ErrorFormik";
import CustomInput from "../../../ui/custom-inputs/CustomInput";
import Label from "../../../ui/custom-inputs/Label";
import {
  postAddCategotyQueryFn,
  postAmenityQueryFn,
  postUploadImageQueryFn,
} from "../../../../../queries/queryFunctions/privetQueryFns/postQueryFns";
import DropdownBlog from "../../../ui/DropdownBlog";
import {
  addAmenitiySchema,
  addExperienceTypes,
} from "../../../../../utils/forms-schemas";
import FileDropzone from "../../../ui/FileDropzone";
import {
  ACCEPT_IMAGES_TYPES_OBJECT,
  MAX_SIZE_IMAGE,
  S3_FILES_NAME,
} from "../../../../../utils/constants";
import {
  deleteAmenityQueryFn,
  deleteCategotyQueryFn,
} from "../../../../../queries/queryFunctions/privetQueryFns/deleteQueryFns";
import {
  patchAmenityQueryFn,
  patchCategotyQueryFn,
} from "../../../../../queries/queryFunctions/privetQueryFns/patchQueryFns";
import CategoryForm from "../../../ui/custom-inputs/CategoryForm";
import EditableSelect from "../../../ui/custom-inputs/select-inputs/EditableSelect";
import Pagination from "../../../ui/Pagination";
import CustomModal from "../../../ui/Modal";

const AmenitiesComponent = () => {
  const [activePage, setActivePage] = useState(0);
  const { setIsLoading } = usePublicContext();
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);
  const [amenitieIdToDelete, setAmenitiesIdToDelete] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [iconPath, setIconPath] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isNameInputVisible, setIsNameInputVisible] = useState(false);
  const [limit, setLimit] = useState(10);
  const [categoryOptionData, setCategoryOptionData] = useState({});
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [showCategoryAlert, setShowCategoryAlert] = useState(false);
  const [categorySelectedItem, setCategorySelectedItem] = useState(undefined);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      nameEn: "",
      nameAr: "",
      category: "",
      iconPath: "",
      categoryNameEn: "",
      categoryNameAr: "",
    },
    validationSchema: addAmenitiySchema,
    onSubmit: onSubmit,
  });

  const handleCategorySubmit = () => {
    if (categorySelectedItem) {
      updateCategoryMutation({
        id: categorySelectedItem.value,
        nameEn: values.categoryNameEn,
        nameAr: values.categoryNameAr,
      });
    } else {
      addCategoryMutation({
        nameEn: values.categoryNameEn,
        nameAr: values.categoryNameAr,
      });
    }
    setIsNameInputVisible(false);
    setFieldValue("categoryNameEn", "");
    setFieldValue("categoryNameAr", "");
  };

  const {
    mutate: addCategoryMutation,
    isPending: isLoadingCategoryMutate,
    isError: isErrorCategoryMutate,
  } = useMutation({
    mutationFn: postAddCategotyQueryFn,
    onSuccess(data) {
      toast.success("Category Added Successfully");
      refetchCategoryData();
      setCategoryOptionData(null);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: updateCategoryMutation,
    isPending: isLoadingUpdateCategoryMutation,
    isError: isErrorUpdateCategoryMutation,
  } = useMutation({
    mutationFn: patchCategotyQueryFn,
    onSuccess(data) {
      toast.success("Category Updated Successfully");
      refetchCategoryData();
      setCategoryOptionData(null);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    data: categoryData,
    isPending: isLoadingCategoryData,
    isError: isErrorCategoryData,
    fetchStatus: fetchStatusCategoryData,
    refetch: refetchCategoryData,
  } = useQuery({
    queryKey: ["category-list"],
    queryFn: getAmenityCategoriesList,
  });

  const {
    data: AmenitieData,
    isPending: isLoadingAmenitieData,
    isError: isErrorAmenitieData,
    refetch,
  } = useQuery({
    queryKey: ["amenitie", categoryData, limit],
    queryFn: getAmenitiesList,
  });

  const {
    mutate: addMutation,
    isPending: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation({
    mutationFn: postAmenityQueryFn,
    onSuccess(data) {
      toast.success("Amenitie Added Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  function onSubmit() {
    if (selectedItem) {
      updateMutation({
        id: selectedItem._id,
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        category: values?.category?.value,
        iconPath: values.iconPath,
      });
    } else {
      addMutation({
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        category: values?.category?.value,
        iconPath: values.iconPath,
      });
    
    }
    resetForm();
    setIsSlideOverOpen(false);
  }

  const {
    mutate: mutateUploadImage,
    isPending: isLoadingUploadImage,
    isError: IsErrorUploadImage,
    data: dataUploadImage,
    error: errorUploadImage,
  } = useMutation({
    mutationFn: postUploadImageQueryFn,
    onSuccess(data) {
      setIsLoading(false);
      setFieldValue("iconPath", data);
      setIconPath("");
      toast.success("Upload Icon successfully");
    },
    onError(error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    },
  });

  async function onImageUpload() {
    if (iconPath?.length && iconPath[0] instanceof File) {
      setIsLoading(true);
      mutateUploadImage({
        file: iconPath[0],
        type: S3_FILES_NAME.ICONS,
      });
    }
  }

  const {
    mutate: updateMutation,
    isPending: isLoadingUpdateMutation,
    isError: isErrorUpdateMutation,
  } = useMutation({
    mutationFn: patchAmenityQueryFn,
    onSuccess(data) {
      toast.success("Amenity Updated Successfully");
      refetch();
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: deleteMutation,
    isPending: isLoadingDeleteMutation,
    isError: isErrorDeleteMutation,
  } = useMutation({
    mutationFn: deleteAmenityQueryFn,
    onSuccess(data) {
      toast.success("Amenity Deleted Successfully");
      refetch();
      setShowAlert(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  const {
    mutate: deleteCategoryMutation,
    isPending: isLoadingDeleteCategoryMutation,
    isError: isErrorDeleteCategoryMutation,
  } = useMutation({
    mutationFn: deleteCategotyQueryFn,
    onSuccess(data) {
      toast.success("Category Deleted Successfully");
      refetchCategoryData();
      setShowCategoryAlert(false);
    },
    onError(error) {
      toast.error("Something went wrong, please try later!");
    },
  });

  useEffect(() => {
    onImageUpload();
  }, [iconPath]);

  useEffect(() => {
    setIsLoading(isLoadingAmenitieData);
  }, [isLoadingAmenitieData]);

  const openSlideOver = () => {
    setIsSlideOverOpen(true);
  };

  const closeSlideOver = () => {
    setSelectedItem(null);
    setFieldValue("nameEn", "");
    setFieldValue("nameAr", "");
    setFieldValue("category", "");
    setFieldValue("iconPath", "");
    setIsSlideOverOpen(false);
    setIsNameInputVisible(false);
  };

  const closeCategoryForm = () => {
    setSelectedItem(null);
    setIsSlideOverOpen(true);
    setIsNameInputVisible(false);
    setFieldValue("categoryNameEn", "");
    setFieldValue("categoryNameAr", "");
    setCategoryOptionData(null);
  };

  const handleDelete = (id) => {
    setAmenitiesIdToDelete(id);
    setShowAlert(true);
  };

  const handleConfirmDelete = () => {
    deleteMutation(amenitieIdToDelete);
    setShowAlert(false);
  };

  const handleCategoryDelete = (id) => {
    setCategoryIdToDelete(id);
    setShowCategoryAlert(true);
  };

  const handleConfirmCategoryDelete = () => {
    deleteCategoryMutation(categoryIdToDelete);
    setShowCategoryAlert(false);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setFieldValue("nameEn", item.nameEn);
    setFieldValue("nameAr", item.nameAr);
    setFieldValue("category", {
      label: item.category[0]?.nameEn,
      value: item.category[0]?._id,
    });
    setFieldValue("iconPath", item.iconPath);
    setIsSlideOverOpen(true);
  };

  const handleClick = (pageIndex) => {
    setActivePage(pageIndex);
  };

  const startIndex = activePage * pageSize;
  const endIndex = Math.min(startIndex + pageSize, AmenitieData?.length);

  useEffect(() => {
    let res = (categoryData || [])
      .filter((item) => item.nameEn)
      .map((item) => ({
        label: item.nameEn || "",
        value: item._id,
        nameAr: item.nameAr,
      }));
    setCategoriesList(res);
  }, [categoryData]);

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setLimit(newSize);
  };

  const handleAddButtonClick = () => {
    setFieldValue("categoryNameEn", "");
    setFieldValue("categoryNameAr", "");
    setIsSlideOverOpen(true);
    setIsNameInputVisible(true);
  };

  const handleEditOption = (item) => {
    if (item) {
      setFieldValue("categoryNameEn", item.label);
      setFieldValue("categoryNameAr", item.nameAr);
      setCategorySelectedItem(item);
      setIsSlideOverOpen(true);
      setIsNameInputVisible(true);
      setCategoryOptionData(item);
    }
  };

  return (
    <div className='table-responsive'>
      <h2>Amenities</h2>
      <CustomModal
        title='Warning!'
        description='Deleting this type will remove it from the associated records.'
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        buttonConfirmAction={handleConfirmDelete}
        buttonConfirmText={"Confirm Delete"}
        buttonCloseText='Cancel'
      />
      <div
        className='guest-calendar'
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button className='me-2' variant='secondary' onClick={openSlideOver}>
          Add
        </Button>
      </div>

      <table className='table card-table display mb-4 shadow-hover default-table .dataTablesCard dataTable no-footer'>
        <thead>
          <tr>
            <th className='sorting_asc'>Name</th>
            <th className='sorting'>اﻻسم</th>
            <th className='sorting_asc'>Category</th>
            <th className='sorting'>Icon</th>
            <th className='sorting bg-none'></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(AmenitieData) &&
            AmenitieData.slice(startIndex, endIndex).map((amenitie, index) => (
              <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                <td>
                  <div>
                    <h5 className='text-nowrap'>{amenitie.nameEn}</h5>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>{amenitie.nameAr}</h5>
                  </div>
                </td>
                <td>
                  <div>
                    <h5 className='text-nowrap'>
                      {amenitie.category[0]?.nameEn}
                    </h5>
                  </div>
                </td>
                <td>
                  <div>
                    {amenitie.iconPath && (
                      <img
                        src={amenitie.iconPath}
                        alt='Icon'
                        style={{ maxWidth: "20px", maxHeight: "20px" }}
                      />
                    )}
                  </div>
                </td>
                <DropdownBlog
                  showArchiveOption={false}
                  showDeleteOption={true}
                  showPublishOption={false}
                  experienceTypeId={amenitie._id}
                  handleDelete={() => handleDelete(amenitie._id)}
                  handleEdit={() => handleEdit(amenitie)}
                />
              </tr>
            ))}
        </tbody>
      </table>

      <Pagination
        startIdx={startIndex}
        endIdx={endIndex}
        pageSize={pageSize}
        activePage={activePage}
        handleClick={handleClick}
        handleChangePageSize={handleChangePageSize}
        data={AmenitieData}
        itemsPerPage={pageSize}
        totalCount={AmenitieData?.length}
      />

      <SlideOver
        isOpen={isSlideOverOpen}
        onClose={closeSlideOver}
        onSubmit={handleSubmit}
      >
        <div>
          <h4>{selectedItem ? "Edit" : "Add"} Amenitie</h4>
          <CustomModal
            title='Warning!'
            description='Deleting this type will remove it from the associated records.'
            isOpen={showCategoryAlert}
            setIsOpen={setShowCategoryAlert}
            buttonConfirmAction={handleConfirmCategoryDelete}
            buttonConfirmText={"Confirm Delete"}
            buttonCloseText='Cancel'
          />
          <section>
            <Label forId='iconPath' text='Icon' isRequired={true} />
            <div className='w-100 d-flex flex-column gap-3'>
              {values?.iconPath && (
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  className='img-fluid img-thumbnail rounded'
                  src={values?.iconPath}
                  alt='iconPath'
                />
              )}
              <FileDropzone
                name={"iconPath"}
                setState={setIconPath}
                value={false}
                filesAccept={ACCEPT_IMAGES_TYPES_OBJECT}
                maxSize={MAX_SIZE_IMAGE}
              />
              <ErrorFormik
                isError={errors?.iconPath}
                isTouched={touched?.iconPath}
                error={errors?.iconPath}
              />
            </div>
          </section>
          {isNameInputVisible && (
            <CategoryForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleCategorySubmit={handleCategorySubmit}
              categoryOptionData={categoryOptionData}
              onClose={closeCategoryForm}
            />
          )}
          <section className='row'>
            <Label forId='category' text='category' isRequired={true} />
            <div className='w-100 d-flex align-items-center'>
              <div style={{ flex: 1 }}>
                <EditableSelect
                  isLoading={isLoadingCategoryData}
                  defaultValue={values.category?.label}
                  placeholder='Search'
                  isClearable={false}
                  isSearchable={true}
                  isDisabled={false}
                  isRtl={false}
                  options={categoriesList}
                  handleChange={(val) => setFieldValue("category", val)}
                  handleBlur={() => {
                    handleBlur("category");
                  }}
                  id={"category"}
                  name={"category"}
                  value={values.category}
                  onEdit={handleEditOption}
                  handleDelete={handleCategoryDelete}
                />
              </div>
              <button
                className='btn btn-secondary ms-2'
                onClick={handleAddButtonClick}
              >
                Add New
              </button>
            </div>
          </section>

          <section className='row'>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='placeType' text='Name' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='Name'
                  id='nameEn'
                  name='nameEn'
                  type='text'
                  value={values?.nameEn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.nameEn}
                  isTouched={touched?.nameEn}
                  error={errors?.nameEn}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group mb-3'>
                <Label forId='nameAr' text='الاسم ' isRequired={true} />
                <CustomInput
                  withFocus={true}
                  placeholder='الاسم'
                  id='nameAr'
                  name='nameAr'
                  type='text'
                  value={values?.nameAr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorFormik
                  isError={errors?.categoryNameEn}
                  isTouched={touched?.nameAr}
                  error={errors?.nameAr}
                />
              </div>
            </div>
          </section>
        </div>
      </SlideOver>
    </div>
  );
};

export default AmenitiesComponent;
